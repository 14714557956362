html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}
#app,
.ant-app {
  width: 100%;
  height: 100%;
}
.ant-app {
  background-color: var(--ant-color-bg-layout);
}
* {
  box-sizing: border-box;
}
